<template>
  <div class="container-fluid billing">
    <h1>Billing</h1>

    <div class="container billing__box">
      <div class="billing__datefilter">
        <date-range-picker
          :ref="`picker`"
          v-model="dateRange"
          @update="billingbyDate()"
        >
          <template v-slot:input=""> Escolha o Range de datas </template>
        </date-range-picker>
      </div>
  <div class="row">
    <div class="billing__chart">
        <Barchart v-if="billing_chart != null" :chartData="billing_chart" :chartOptions="paragraph_options"/>
    </div>
  </div>
    
     
        <table class="table tableBilling" :key="key">
          <thead>
            <tr>
              <th scope="col" colspan="3" class="tableBilling--products">
                Documentos processados
              </th>
            </tr>
            <tr class="tableBilling--specialrow">
              <th scope="col">Produto</th>
              <th scope="col">Número de Páginas</th>
              <th scope="col">Total de Documentos</th>
            </tr>
             <template v-for="(bil, key) in billing_products">
            <tr class="tableBilling--specialrow" :key="key">
              <td scope="col">{{ key }}</td>
              <td scope="col">{{ bil.number_pages }}</td>
              <td scope="col">{{ bil.count }}</td>
            </tr>

             </template>
             
          </thead>
        </table>
    <div class="row billing__box--search">
       <template v-if="searchedPages != 0 && searchBox != ''">
        <div class="searchedPages">Páginas: {{searchedPages}}</div>
      </template>
      <template v-if="searchedCount != 0 && searchBox != ''">
        <div class="searchedTotal">Documentos: {{searchedCount}}</div>
      </template>
      <input type="text" v-model="searchBox" placeholder="Busca por cliente..." @keyup="filterClient()">
    </div>
      <template v-for="(bil, key) in billing_info">
        <table class="table tableBilling" :key="key">
          <thead>
            <tr>
              <th scope="col" colspan="3" class="tableBilling--client">
                {{ key }}
              </th>
            </tr>
            <tr>
              <th scope="col">Produto</th>
              <th scope="col">Número de Páginas</th>
              <th scope="col">Total de Documentos</th>
            </tr>
            <tr v-for="(bil_, key_) in bil['products']" :key="key_">
              <td scope="col">{{ bil_["product"] }}</td>
              <td scope="col">{{ bil_["number_pages"] }}</td>
              <td scope="col">{{ bil_["count"] }}</td>
            </tr>
            <tr>
              <th scope="col" colspan="3" class="tableBilling--total">
                Total de documentos de {{ key }}: {{ bil["totalcount"] }}
              </th>
            </tr>
          </thead>
        </table>
      </template>
    </div>
  </div>
</template>
<script type="text/javascript">
import { BillingMixin } from "./billing.mixin";
import Barchart from '../../components/ui/Barchart/Barchart.vue'
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  created() {},
  data() {
    return {};
  },
  watch: {},
  props: {},
  components: { DateRangePicker, Barchart },
  name: "Billing",
  mixins: [BillingMixin],
};
</script>
<style src="./billing.less" lang="less" />
