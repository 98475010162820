import { getBilling } from '../../services/billing.services'

import { string_to_slug } from '../../helpers/helpers'
import moment from 'moment';
import  _  from 'lodash';
export const BillingMixin = {
    data() {
        return {
            billing_info: [],
            billing_products: [],
            billing_backup_info: [],
            billing_chart: null,
            total_pages: 0,
            total_count: 0,
            searchedPages:0,
            searchedCount:0,
            searchBox: '',
            dateRange: {
                startDate: new Date(),
                endDate: new Date(),
            },
            paragraph_options:
            {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                        },
                    }, ],
                    xAxes: [{
                        ticks: {
                            beginAtZero: true,
                        },
                    }, ],
                },
                legend: {
                    display: false,
                },
            }
        };
    },
    created() { },
    mounted() {
        this.billing()
    },
    filters: {},
    watch: {},
    computed: {


    },
    methods: {
        async billing() {
            try {
                this.searchedPages = 0
                this.searchedCount = 0
                const { data } = await getBilling()
                console.log(data)
                this.billing_info = this.$duplicate(data.data.billing)
                this.billing_backup_info = this.$duplicate(data.data.billing)
                this.billing_products = this.$duplicate(data.data.billing_products)
                this.billing_chart = this.$duplicate(data.data.data_chart)
                this.total_count = data.data.total_count
                this.total_pages = data.data.total_pages


            } catch (e) {
                this.$tokentest(e.response.status)
                this.$toast.error(e.response.data.msg)
            }
        },

        filterClient() {
            this.searchedPages = 0
            this.searchedCount = 0

                let search = this.$duplicate(this.billing_backup_info)
                let filteredContent = {}
                for (const i in search) {

                    if (string_to_slug(i).search(string_to_slug(this.searchBox)) != -1) {

                        filteredContent[i] = search[i]
                        this.searchedCount += parseInt(search[i].totalcount)

                        for (const _i in search[i]['products']) {
                            
                            this.searchedPages += parseInt(search[i]['products'][_i]['number_pages'])
                        }
                    }
                }
                this.billing_info = filteredContent
                if (_.isEmpty(this.billing_info)) {
                   this.searchedPages = 0
                   this.searchedCount = 0
                }

        },

        async billingbyDate() {
            try {
                this.searchedPages = 0
                this.searchedCount = 0
                let obj = {
                    'datafrom': '',
                    'datato': '',
                }
                obj.datafrom = moment(this.dateRange.startDate).locale('en').format('YYYY-MM-DD')
                obj.datato = moment(this.dateRange.endDate).locale('en').format('YYYY-MM-DD')
                const { data } = await getBilling(obj)
                this.billing_info = this.$duplicate(data.data.billing)
                this.billing_backup_info = this.$duplicate(data.data.billing)
                this.billing_products = this.$duplicate(data.data.billing_products)
                this.billing_chart = this.$duplicate(data.data.data_chart)
                this.total_count = data.data.total_count
                this.total_pages = data.data.total_pages


            } catch (e) {
                this.$tokentest(e.response.status)
                this.$toast.error(e.response.data.msg)
            }
        }


    },
};